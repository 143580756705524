<template>
    <div class="d-flex" :class="alignment" >
        <div class="d-flex flex-column justify-center">
            <span class="d-none d-xl-flex justify-end mr-1">Status</span>
        </div>
        <div class="d-flex flex-column justify-center">
            <v-btn-toggle
                v-model="internal"
                dense
                dark
                mandatory
                @change="emitChange"
            >
                <v-btn  color="grey" value="Open">
                    Open
                </v-btn>
                <v-btn  color="grey" value="Closed">
                    Closed
                </v-btn>

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip }">
                        <v-btn color="grey" value="Incomplete" >
                            <v-icon v-on="{ ...tooltip }">mdi-file-remove-outline</v-icon>
                        </v-btn>
                        </template>
                        <span>Incomple case (usually missing total cost) - not included in PowerBI</span>
                    </v-tooltip>

                <v-btn v-if="allbutton" color="grey" value="all" >
                    All
                </v-btn>
            </v-btn-toggle>
        </div>
    </div>
</template>

<script>
export default {
    name: 'StatusWidget',
    props: ['value', 'allbutton', 'justify'],
    data: () => ({
        internal: null,
        alignment: null,
    }),
    watch: {
      'value': {
        immediate: true,
        handler(newValue) {
            if(newValue == 'null') {
                this.internal = null
            } else {
                this.internal = newValue
            }
        }
      }
    },
    methods: {
        emitChange() {
            this.$emit('input', this.internal)
            this.$nextTick(function () {
                this.$emit('change', this.internal)
            })
        }
    },
    mounted: function(){
        this.alignment = this.justify ? this.justify : 'justify-end'
    }
}
</script>

<style lang="scss" >
.v-btn-toggle > .v-btn.v-btn {
    .v-icon {
      color: darkslategrey !important;
    }
  .v-btn__content {
    color:  darkslategrey !important;
    font-weight: bold;
  }
}

.v-btn-toggle > .v-btn.v-btn--active:before {
    opacity: 0;
}
.v-btn-toggle > .v-btn.v-btn--active {
    background-color: #1976d2 !important;
  .v-icon {
    color: white !important;
  }
  .v-btn__content {
    color:  white !important;
    font-weight: bold;
  }

}
</style>