<template>
  <v-container style="" fluid class="fill-height pa-0" v-if="!$store.state.loading">
    <v-row  class="fill-height">
        <v-col :cols="getLeftSide">
              <v-row class="d-flex flex-column fill-height">
                  <v-col class="white--text grow overflow-auto">
                    <filter-and-claims-list />
                  </v-col>
            </v-row>
        </v-col>
        <v-col :cols="getRightSide">
             <v-row class="d-flex flex-column fill-height" >
                <v-col class="white--text grow overflow-auto">
                  <tabs />
                </v-col>
            </v-row>
        </v-col>
    </v-row>
  </v-container>

</template>
<script>
import FilterAndClaimsList from '../components/FilterAndClaimsList'
import Tabs from '../components/Tabs'

  export default {
    name: 'ClaimsView',
    props: ['data', 'id'],
    data: () => ({
      loaded: false,
      state: true,
    }),
    components: {
      FilterAndClaimsList, Tabs
    },
    watch: {
    },
    mounted: function(){
      this.loaded = false;
      this.init().then(() => {
        if(this.$route.params.id && this.$store.state.claims) {
          this.$store.dispatch('showLoader').then(() =>
            this.$store.dispatch('openClaim', {claim: this.$store.state.claims.find(f => f.id == this.$route.params.id)}).then(
              () => this.$store.dispatch('hideLoader').then(
                () => this.loaded = true
              )
            )
          )
        } else {
           this.$store.dispatch('hideLoader').then(() => this.loaded = true)
        }
      })
    },
    computed: {
      getMiddleSide() {
          if(this.$store.state.openClaim) {
            return 0
          }
          return 4
        },
        getLeftSide() {
          if(this.$store.state.openClaim) {
            return 5
          }
          return 8
        },
        getRightSide() {
          if(this.$store.state.openClaim) {
            return 7
          }
          return 0
        },
        showLoader () {
            return this.loaded && this.$store.state.loading
        },
        selection() {
          return (this.$store.state.openClaim)
        },
    },
    methods: {
      init() {
        return Promise.all([
          this.$store.dispatch('getClaims'),
          this.$store.dispatch('getCrew'),
          //this.$store.dispatch('getVessels'),
          this.$store.dispatch('getDimensions'),
          this.$store.dispatch('getMetadata'),
          this.$store.dispatch('getAudits'),
          this.$store.dispatch('getAttachments'),
        ])
      },
      test(test) {
        console.log(test)
      }
    },
  }
</script>

<style scoped>
.card {
  /* Add shadows to create the "card" effect */
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) !important;
    transition: 0.3s;
    background-color: #e9eef2;
}
.v-image {
  filter: drop-shadow(#1976d2 2px 2px 2px);
}
.drop {
  filter: drop-shadow(3px 3px 2px black);
}
.chosen_vessel {
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
}
html {
  overflow: hidden !important;
}

.v-card {
  display: flex !important;
  flex-direction: column;
}

.v-card__text {
  flex-grow: 1;
  overflow: auto;
}
</style>