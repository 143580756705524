<template>
  <v-container fluid class="fill-height">
  <v-alert
          class="flex-grow-1"
          :value="errorbar"
          type="error"
          prominent
          elevation="2"
          transition="slide-y-transition"
          >
           {{ errorText }}
  </v-alert>

   <v-card elevation="2" class="ma-1 grow">
        <v-card-text class="fill-height">
          <v-row>
            <v-col cols="12" md="6" lg="4" xl="3" class="d-flex flex-row space-between">
              <v-sheet class="pr-3">
                <date-importer :parse="parseDate" />
              </v-sheet>
                <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="claim.IncidentDate"
                        label="Incident date"
                        append-icon="mdi-calendar"
                        outlined
                        dense
                        readonly
                        hide-details="auto"
                        v-bind="attrs"
                        v-on="on"
                        @click:append="menu = !menu"
                        @change="saveChanges()"
                      ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="claim.IncidentDate"
                      @input="dateFromPicker" />
                </v-menu>
            </v-col>

            <v-col cols="12" md="6" lg="4" xl="3">
              <v-combobox
                v-model="claim.ClaimsHandler" @change="saveChanges()" outlined label="Claims Handler"
                dense hide-details="auto" :items="handlers"></v-combobox>
            </v-col>

             <v-col cols="12" md="6" lg="4" xl="3">
              <v-text-field
                v-model="claim.CaseReference"
                outlined
                label="Case reference"
                dense
                hide-details="auto"
                @change="checkCaseReference(claim)">
              </v-text-field>
            </v-col>


            <v-col v-if="isPerson" class="d-none d-xl-flex" cols="12" md="5" lg="6" xl="3">
            </v-col>


            <v-col v-if="!isPerson" cols="12" md="6" lg="4" xl="3">
                <v-checkbox
                  outlined
                  v-model="claim.InsuranceClaim"
                  dense
                  :false-value="0"
                  :true-value="1"
                  hide-details="auto"
                  @change="saveChanges()"
                  label="Insurance Claimed?" />

            </v-col>

            <v-col cols="12" md="6" lg="4" xl="3">
              <v-select
                v-model="claim.Vessel"
                outlined dense hide-details="auto"
                :items="vessels"
                @change="saveChanges()"
                label="Vessel">
              </v-select>
            </v-col>


            <v-col v-if="!isPerson" cols="12" md="6" lg="4" xl="2">
              <v-combobox
                dense
                outlined
                :items="getHMClubs"
                v-model="claim.HMClub"
                hide-details="auto"
                label="H&M Club"
                :prepend-inner-icon="canPrependImport"
                @change="saveChanges()"
                @click:prepend-inner="importHM()"
                />
            </v-col>

            <v-col v-if="isPerson" cols="12" md="6" lg="4" xl="3">
              <v-combobox
                v-if="isPerson"
                dense
                outlined
                :items="getPIClubs"
                v-model="claim.PIClub"
                hide-details="auto"
                label="P&I Club"
                :prepend-inner-icon="canPrependImport"
                @change="saveChanges()"
                @click:prepend="importPI()"/>
            </v-col>
          <v-col v-if="!isPerson" cols="12" md="4" lg="4" xl="2">
            <v-combobox
              dense
              outlined
              :items="getPIClubs"
              v-model="claim.PIClub"
              hide-details="auto"
              label="P&I Club"
              :prepend-inner-icon="canPrependImport"
              @change="saveChanges()"
              @click:prepend="importPI()"/>
            </v-col>
            <v-col v-if="isPerson" cols="12" md="3" lg="2" xl="3">
              <v-text-field dense
               hide-details="auto" v-model="claim.PolicyYear" outlined type="number" label="PolicyYear" @change="saveChanges()"></v-text-field>
            </v-col>
            <v-col v-if="!isPerson" cols="12" md="3" lg="2" xl="2">
              <v-text-field dense
               hide-details="auto" v-model="claim.PolicyYear" outlined type="number" label="PolicyYear" @change="saveChanges()"></v-text-field>
            </v-col>


            <v-col v-if="isPerson" class="d-none d-xl-flex" cols="12" md="5" lg="6" xl="3">
            </v-col>

            <v-col v-if="!isPerson" cols="12" md="5" lg="6" xl="3">
              <status-widget v-model="claim.Status" @change="saveChanges()" />
            </v-col>
            <v-col v-if="isPerson" cols="12" md="5" lg="6" xl="3">
              <status-widget v-model="claim.Status" @change="saveChanges()" justify="justify-center" />
            </v-col>

            <v-col v-if="isPerson" cols="12" md="6" lg="4" xl="7">
                <v-checkbox
                  outlined
                  v-model="claim.InsuranceClaim"
                  dense
                  :false-value="0"
                  :true-value="1"
                  hide-details="auto"
                  @change="saveChanges()"
                  label="Insurance Claimed?" />

            </v-col>

          </v-row>

        <div v-if="isPerson" class="mt-3">
          <v-row>
              <v-col cols="12" md="6" lg="3">
                  <v-text-field dense hide-details="auto"  v-model="crew.Name" outlined label="Name" @change="saveChanges()"></v-text-field>
              </v-col>
              <v-col cols="12" md="6" lg="3">
                    <v-slider
                      v-model="crew.Age"
                      color="orange"
                      label="Age"
                      min="18"
                      max="100"
                      thumb-label
                      :hint="`${crew.Age}`"
                      persistent-hint
                      @change="saveChanges()"
                      ></v-slider>
                  <!-- <v-text-field dense hide-details="auto" v-model="crew.Age" outlined label="Age"></v-text-field> -->
              </v-col>
              <v-col cols="12" md="6" lg="3">
                  <v-combobox
                      outlined
                      :items="getRanks"
                      v-model="crew.Rank"
                      dense
                      hide-details="auto"
                      @change="saveChanges()"
                      label="Rank" />
              </v-col>
              <v-col cols="12" md="6" lg="3">
                <v-combobox
                    v-if="claim.DamageType==='ECR'"
                    outlined
                    :items="getECROfficerFamily"
                    v-model="crew.ECRCoverage"
                    dense
                    hide-details="auto"
                    @change="saveChanges()"
                    label="ECR Coverage" />
              </v-col>

          </v-row>
          <v-row>
              <v-col cols="12" md="6" lg="3">
                  <v-combobox
                      outlined
                      :items="getInjuryIllnessTypes"
                      v-model="crew.InjuryIllnessType"
                      dense
                      hide-details="auto"
                      @change="saveChanges()"
                      :label="claim.DamageTypeSubCategory+' Type'" />
              </v-col>
              <v-col cols="12" md="6" lg="3" v-if="claim.DamageTypeSubCategory != 'Other'">
                  <v-combobox
                      outlined
                      :items="getBodyArea"
                      v-model="crew.BodyArea"
                      dense
                      hide-details="auto"
                      @change="saveChanges()"
                      label="Body Area" />
              </v-col>
              <v-col cols="12" md="6" lg="3" v-if="claim.DamageTypeSubCategory != 'Other'">
                  <v-combobox
                      outlined
                      :items="getMedicalRootCause"
                      v-model="crew.MedicalRootCause"
                      dense
                      hide-details="auto"
                      @change="saveChanges()"
                      label="Medical Root Cause" />
              </v-col>
              <v-col cols="12" md="6" lg="3" v-if="claim.DamageTypeSubCategory != 'Other'">
                  <v-combobox
                      outlined
                      :items="getDiagnosis"
                      v-model="crew.Diagnosis"
                      dense
                      hide-details="auto"
                      @change="saveChanges()"
                      label="Diagnosis" />
              </v-col>
          </v-row>
          <v-row>
              <v-col cols="12" md="6" lg="3"  class="d-flex justify-center">
              <v-checkbox
                  outlined
                  v-model="crew.WorkRelated"
                  dense
                  :false-value="0"
                  :true-value="1"
                  hide-details="auto"
                  @change="saveChanges()"
                  label="Work Related?" />
              </v-col>
              <v-col cols="12" md="6" lg="3">
              <v-combobox
                  outlined
                  :items="getSignedOff"
                  v-model="crew.SignedOff"
                  dense
                  hide-details="auto"
                  label="Signed Off"
                  @change="saveChanges()" />
              </v-col>
              <v-col cols="12" md="6" lg="3">
                <v-combobox
                    outlined
                    :items="getCrewAgencies"
                    v-model="claim.CrewAgency"
                    dense
                    hide-details="auto"
                    label="Crew Agency"
                    @change="saveChanges()"/>
            </v-col>
            <v-col cols="12" md="6" lg="3">
                    <v-combobox
                        outlined
                        :items="getNationality"
                        v-model="claim.Nationality"
                        dense
                        hide-details="auto"
                        label="Nationality"
                        @change="saveChanges()"/>
            </v-col>
          </v-row>
        </div>

          <v-row>
            <v-col cols="12">
              <v-text-field
                dense
                hide-details="auto"
                v-model="claim.ExternalReference"
                outlined
                label="External reference"
                :append-icon="copyOrOpenIcon(claim.ExternalReference)"
                @click:append="copyOrOpen(claim.ExternalReference)"
                @change="saveChanges()">
                </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="claim.FolderReference"
                dense
                hide-details="auto"
                outlined
                label="Folder reference"
                :append-icon="copyOrOpenIcon(claim.FolderReference)"
                @click:append="copyOrOpen(claim.FolderReference)"
                @change="saveChanges()"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="flex-column fill-height">
            <v-col cols="auto" class="flex-shrink-1">
              <v-text-field dense
                            append-icon="mdi-clipboard-outline"
                            hide-details="auto"
                            v-model="claim.Subject"
                            outlined
                            label="Subject"
                            @change="saveChanges()"
                            @click:append="copyToClipboard(`${claim.CaseReference} - ${claim.IncidentDate} - ${claim.VesselName} - ${claim.Subject}`)"></v-text-field>
            </v-col>
            <v-col cols="auto" class="flex-grow-1">
              <v-textarea
                v-if="!isPerson"
                v-model="claim.Description"
                outlined
                auto-grow
                class="flex-grow-1"
                @change="saveChanges()"
                label="Description"></v-textarea>
              <v-textarea
                v-if="isPerson"
                v-model="crew.Description"
                outlined
                auto-grow
                class="flex-grow-1"
                @change="saveChanges()"
                label="Description"></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
    </v-card>
    <v-snackbar
      v-model="snackbar"
    >
      <span class="blue-grey--text lighten-3">Copied to clipboard:</span> {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbarText = null"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>



</v-container>
</template>

<script>
import { getISO8601ShortDateFromDate } from './Tools'
import DateImporter from './DateImporter'
import StatusWidget from './StatusWidget'

  export default {
    name: 'DetailsTab',
    props: ['claimData', 'crewData', 'save'],
    components: { DateImporter, StatusWidget},
    data: () => ({
        menu: false,
        personDamageHierarky: {
            'Crew': {   Injury: ['Amputation', 'Burn', 'Constitution', 'Crush', 'Cut', 'Fracture', 'General pain', 'Laceration', 'Other', 'Strain/Sprain'],
                        Illness: ['Infectious disease', 'Mental disease', 'Non-infectous disease', 'Physical disease'],
                        Other: ['Missing at sea', 'Stowaways', 'Desertion']
                    },
            'ECR': {   Injury: ['Amputation', 'Burn', 'Constitution', 'Crush', 'Cut', 'Fracture', 'General pain', 'Laceration', 'Other', 'Strain/Sprain'],
                        Illness: ['Infectious disease', 'Mental disease', 'Non-infectous disease', 'Physical disease'],
                        Other: ['Missing at sea', 'Stowaways', 'Desertion']
                    },
        },
        oldCaseRef: null,
        crew: {

        },
        claim: {
          CaseReference: null,
          ClaimClosed: null,
          ClaimCreated: null,
          ClaimsHandler: null,
          CrewAgency: null,
          DamageType: null,
          DamageTypeSubCategory: null,
          DeductibleAmount: null,
          Description: null,
          ExternalReference: null,
          FolderReference: null,
          HMClub: null,
          HMDeductibleAmount: null,
          HMVesselInsuredAmount: null,
          HMVesselInsuredAmountFI: null,
          HMVesselInsuredAmountHI: null,
          IncidentDate: null,
          InsuranceClaim: null,
          InsuranceType: null,
          Nationality: null,
          OutstandingCost: null,
          OwnersCost: null,
          PIClub: null,
          PIDeductibleCargo: null,
          PIDeductibleCrew: null,
          PIDeductibleOther: null,
          PolicyYear: new Date().getFullYear(),
          ReservedAccount: null,
          ReservedCost: null,
          SettledCost: null,
          Status: 'Open',
          Subject: null,
          TotalIncidentCost: null,
          Vessel: null,
        },
        snackbarText: null,
        errorText: null,
        rawDate: '',
        parseDateDialog: false,
    }),
    computed: {
      isPerson() {
        return this.claim.InsuranceType === 'PI' &&
        (this.claim.DamageType === 'ECR' || this.claim.DamageType === 'Crew')
      },
      handlers() {
        return this.$store.state.dimensions.filter(f => f.Dimension === 'Handlers').map(m => m.Value)
      },
      vessels() {
        if(!this.list) return []
        let sortable = this.list.map(item => (item.Vessel)).filter(f => f)
        let metas = this.$store.state.metadata.map(m => m.Name)
        sortable = [...new Set([...metas ,...sortable])]
        sortable.sort((a,b) => a > b ? 1 : (
          (a < b) ? -1 : 0)
        )
        return sortable
      },
      getPIClubs() {
        return this.$store.state.metadata.map(m => m.PIclub).filter(f => f)
      },
      getHMClubs() {
        return this.$store.state.metadata.map(m => m.HMclub).filter(f => f)
      },
      list() {
        return this.$store.state.claims
      },
      getStatus() {
        return this.$store.state.dimensions.filter(p => p.Dimension === 'Status')
      },
      getTypes() {
        return this.$store.state.dimensions.filter(p => p.Dimension === 'Claims Type').
          map((entry) => entry.Value)
      },
      getCategory() {
        if(!this.type) return []
        return this.$store.state.dimensions.filter(p => p.Dimension === 'Claims Category')
      },
      snackbar: {
        get: function () {
          return this.snackbarText != null
        },
        set: function () {
          this.snackbarText = null
        }
      },
      errorbar: {
        get: function () {
          return this.errorText != null
        },
        set: function () {
          this.errorText = null
        }
      },
      getCrewAgencies() {
        return this.$store.state.claims
            .filter((f) => f.CrewAgency)
            .map((m) => m.CrewAgency.trim())
            .sort((a,b)=> a.localeCompare(b))
      },
      getNationality() {
      return this.$store.state.claims
          .filter((f) => f.Nationality)
          .map((m) => m.Nationality.trim())
          .sort((a,b)=> a.localeCompare(b))
      },
      getDiagnosis() {
        return this.$store.state.crew
            .filter((f) => f.Diagnosis)
            .map((m) => m.Diagnosis.trim())
            .sort((a,b)=> a.localeCompare(b))
      },
      getMedicalRootCause() {
        return this.$store.state.crew
          .filter((f) => f.MedicalRootCause)
          .map((m) => m.MedicalRootCause.trim())
          .sort((a,b)=> a.localeCompare(b))
      },
      getRanks() {
        return this.$store.state.crew
          .filter((f) => f.Rank)
          .map((m) => m.Rank.trim())
          .sort((a,b)=> a.localeCompare(b))
      },
      getBodyArea() {
        return this.$store.state.crew
            .filter((f) => f.BodyArea)
            .map((m) => m.BodyArea.trim())
            .sort((a,b)=> a.localeCompare(b))
      },
      getECROfficerFamily() {
        return this.$store.state.crew
          .filter((f) => f.ECRCoverage)
          .map((m) => m.ECRCoverage.trim())
          .sort((a,b)=> a.localeCompare(b))
      },
      getSignedOff() {
        return this.$store.state.crew
            .filter((f) => f.SignedOff)
            .map((m) => m.SignedOff.trim())
            .sort((a,b)=> a.localeCompare(b))
      },

      getInjuryIllnessTypes() {
          return (this.claim.DamageType && this.claim.DamageTypeSubCategory) ? this.personDamageHierarky[this.claim.DamageType][this.claim.DamageTypeSubCategory] : null
      },
      canPrependImport(){
        return (this.claim.Vessel.trim() && this.$store.state.metadata.find(f => f.Name === this.claim.Vessel)) ? 'mdi-import' : null
      },

    },
    watch: {
      claimData: {
        immediate: true,
        handler(newValue) {
          this.claim = newValue
          this.claim.IncidentDate = this.getISO8601ShortDateFromDate(this.claim.IncidentDate)
          this.oldCaseRef = this.claim.CaseReference
        }
      },
      crewData: {
        immediate: true,
        handler(newValue) {
          this.crew = newValue
        }
      },
      errorbar: {
        handler(newVal) {
          if(newVal) { window.setTimeout(() => this.errorText = null, 5000) }
        }
      }
    },
    methods: {
      getISO8601ShortDateFromDate,
      checkCaseReference(item){
        if(this.list.find(f => f.CaseReference === item.CaseReference && f.id != item.id)){
          this.errorText = "That case reference has already been used for another claim and can't be duplicate - reverting case reference back to previous value."
          this.claim.CaseReference = this.oldCaseRef
        } else {
          this.oldCaseRef = item.CaseReference
          this.saveChanges()
        }
      },
      dateFromPicker(ed) {
        this.claim.IncidentDate = ed
        this.menu = false
        this.save()
      },
      parseDate(date) {
        this.claim.IncidentDate = getISO8601ShortDateFromDate(Date.parse(date))
        this.save()
      },
      saveChanges() {
        this.save()
      },
      copyToClipboard(path) {
        this.$copyText(path).then(() => { this.snackbarText=path}, function (e) {
          alert('Can not copy to clipboard! Try using Chrome... ', e)
        })
      },
      copyOrOpenIcon(ref){
        if(ref && ref.startsWith('http'))  {
          return 'mdi-open-in-new'
        }
        return 'mdi-clipboard-outline'
      },
      copyOrOpen(ref){
        if(ref && ref.startsWith('http')) {
          window.open(ref, '_blank').focus();
        } else {
          this.copyToClipboard(ref)
        }
      },
      importPI() {
        if(!this.claim.Vessel) return null
        const meta = this.$store.state.metadata.find(f => f.Name === this.claim.Vessel)
        this.claim.PIClub = meta.PIclub
      },

      importHM() {
        if(!this.claim.Vessel.trim()) return null
        const meta = this.$store.state.metadata.find(f => f.Name === this.claim.Vessel)
        if(meta && meta.HMclub) {
          this.claim.HMClub = meta.HMclub
        }
      },
    },
    mounted: function(){
      //Object.assign(this.claim , this.data)
      this.claim = this.claimData
      this.crew = this.crewData
    }
}
</script>
