'use strict'
const domain = 'https://claims-api.gearbulk.com'
const guiDomain = 'https://claims.gearbulk.com'

module.exports = {
  VESSELS: `${domain}/vessels`,
  DIMENSIONS: `${domain}/dimensions`,
  METADATA: `${domain}/metadata`,
  CLAIMS: `${domain}/claims`,
  CREW: `${domain}/crew`,
  AUDITS: `${domain}/audits`,
  LOGOUT: `${domain}/logout?callback=${guiDomain}/claims`,
  LOGIN: `${domain}/login?callback=${guiDomain}/claims`,
  CREATESESSION: `${domain}/createsession`,
  CONFIRMSESSION: `${domain}/testsession`,
}
