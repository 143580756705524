<template>
    <div>
        <v-row v-for="message of messages" :key="message.id">
            <v-col cols="2">
                <v-card tile>
                    <v-card-title>{{message.CreatedBy}}</v-card-title>
                    <v-card-text>{{new Date(message.CreatedDate).toLocaleDateString()}}</v-card-text>
                </v-card>
            </v-col>
            <v-col cols="10">
                <message :message="message" :saveCallback="save" :deleteCallback="remove"></message>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import Message from './Message.vue'
export default {
    name: 'Chat',
    props: ['search', 'showAll'],
    components: { Message },
    data: () => ({
        loaded: false,
    }),
    computed: {
      messages() {
        let result = [];
        if(!this.$store.state.audits) {return result}
        const pass1 =  this.$store.state.audits
            .filter(f => f.CaseId == this.currentId)
            .filter(f => (!this.showAll ? f.Category==='UserMessage' && f.Message!='' : f.Message!=''))
        if(this.getSearch) {
            result = pass1.filter(f => f.Message.includes(this.getSearch))
        } else {
            result = pass1
        }
        return result
      },
      getSearch(){
          return this.search
      },
      currentId(){
          return this.$store.state.openClaim.id
      }
    },
    methods: {

        save(message) {
            this.$store.dispatch('updateLog', { audit: {id: message.id, Message: message.Message}})
        },
        remove(id) {
            this.$store.dispatch('removeLog', { audit: {id: id}})
        }
     },
    mounted: function(){}
}
</script>