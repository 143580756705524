<template>
  <v-container fluid class="full-height">
    <log-list v-if="$store.state.openClaim" :claimid="$store.state.openClaim.id" />
  </v-container>
</template>


<script>
import LogList from './LogList.vue'
export default {
  name: 'LogTab',
  components: {
    LogList
  },
  data: () => ({
    claim: { },
    loaded: false,
  }),
  methods: {  },
  mounted: function(){
    Object.assign(this.claim , this.data)
  }
}
</script>
<style lang="css">
.logheader .v-text-field__slot input {
   color: #FFF !important;
}
</style>