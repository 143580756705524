<template>
    <div>
        <v-card elevation="4" :class="{user: isChatMessage(message)}" transition="fade-transition">
            <v-card-text class="text-left pa-4">
                <div v-if="!edit" style="white-space: pre-line;">{{message.Message}}</div>
                <div v-if="edit"><v-textarea v-model="alteredMessage"></v-textarea></div>
            </v-card-text>
            <v-card-actions class="justify-end" v-if="canEdit && !isEditing">
                <v-btn @click="edit=true">Edit</v-btn><v-btn @click="remove()"><v-icon>mdi-delete-circle-outline</v-icon></v-btn>
            </v-card-actions>
            <v-card-actions class="justify-end" v-if="canEdit && isEditing">
                <v-btn @click="edit=false">Cancel</v-btn><v-btn @click="save()"><v-icon>mdi-content-save</v-icon>Save </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
export default {
    name: 'Message',
    props: ['message', 'saveCallback', 'deleteCallback'],
    components: { },
    data: () => ({
        alteredMessage: null,
        loaded: false,
        edit: false,
    }),
    computed: {
        isEditing(){
            return this.edit
        },
        canEdit() {
            return this.message.Category === 'UserMessage'
        }
    },
    mounted: function(){
        this.alteredMessage = this.message.Message
    },
    methods: {
        isChatMessage(message){
            return message.Category==='UserMessage'
        },
        save(){
            const newMessage = this.message
            newMessage.Message = this.alteredMessage
            this.edit = false
            this.saveCallback(newMessage)
        },
        remove(){
            this.deleteCallback(this.message.id)
        },
    },
}
</script>

<style lang="css">
.user {
    background-color: #8FB3C2 !important;
}
</style>