/* eslint-disable no-mixed-spaces-and-tabs */
<template>
    <v-card>
        <v-card-text class="text-left">
            <v-row>
                <v-col cols="6">
                    <v-row>
                        <v-col cols="6"><v-text-field label="Built year" v-model="created.BuiltYear" outlined dense></v-text-field></v-col>
                        <v-col cols="6"><v-text-field label="Callsign" v-model="created.CallSign" outlined dense></v-text-field></v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6"><v-text-field label="Code" v-model="created.Code" outlined dense></v-text-field></v-col>
                        <v-col cols="6"><v-text-field label="Dry Weight" v-model="created.DW" outlined dense></v-text-field></v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6"><v-text-field label="Flag" v-model="created.Flag" outlined dense></v-text-field></v-col>
                        <v-col cols="6"><v-text-field label="GT" v-model="created.GT" outlined dense></v-text-field></v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6"><v-text-field label="Finance" v-model="created.finance" outlined dense></v-text-field></v-col>
                        <v-col cols="6"></v-col>
                    </v-row>

                </v-col>
                <v-col cols="6">
                    <v-row>
                        <v-col cols="6"><v-text-field label="IMO" v-model="created.IMO" outlined dense></v-text-field></v-col>
                        <v-col cols="6"><v-text-field label="Manager" v-model="created.Manager" outlined dense class="calculated"></v-text-field></v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6"><v-text-field label="Net" v-model="created.Net" outlined dense></v-text-field></v-col>
                        <v-col cols="6"><v-text-field label="Operator" v-model="created.Operator" outlined dense></v-text-field></v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6"><v-text-field label="Owner" v-model="created.Owner" outlined dense></v-text-field></v-col>
                        <v-col cols="6"><v-text-field label="Type" v-model="created.Type" outlined dense></v-text-field></v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6"></v-col>
                        <v-col cols="6"></v-col>
                    </v-row>
                </v-col>
            </v-row>


            <v-row>
                <v-col cols="4">
                    <v-currency-field
                        class="calculated"
                        v-model="created.InsuredHM"
                        label="Insured H&M"
                        outlined dense hide-details="auto"
                    />
                </v-col>
                <v-col cols="4">
                    <v-currency-field
                        class="calculated"
                        v-model="created.InsuredHI"
                        label="Insured HI"
                        outlined dense hide-details="auto"
                    />
                </v-col>
                <v-col cols="4">
                    <v-currency-field
                        class="calculated"
                        v-model="created.InsuredFI"
                        label="Insured FI"
                        outlined dense hide-details="auto"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4">
                    <v-currency-field
                        class="calculated"
                        v-model="created.PIDeductibleCargo"
                        label="P&I Deductible Cargo"
                        outlined dense hide-details="auto"
                    />
                </v-col>
                <v-col cols="4">
                    <v-currency-field
                        class="calculated"
                        v-model="created.PIDeductibleCrew"
                        label="P&I Dedutcible Crew"
                        outlined dense hide-details="auto"
                    />
                </v-col>
                <v-col cols="4">
                    <v-currency-field
                        class="calculated"
                        v-model="created.PIDeductibleOther"
                        label="P&I Deductible Other"
                        outlined dense hide-details="auto"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4">
                    <v-currency-field
                        class="calculated"
                        v-model="created.HMDeductibleAmount"
                        label="H&M Deductible Amount"
                        outlined dense hide-details="auto"
                    />
                </v-col>
                <v-col cols="4">
                    <v-combobox
                        dense
                        outlined
                        :items="getHMClubs"
                        hide-details="auto"
                        class="calculated"
                        label="H&M Club"
                        v-model="created.HMclub"
                    />
                </v-col>
                <v-col cols="4">
                    <v-combobox
                        dense
                        outlined
                        v-model="created.PIclub"
                        :items="getPIClubs"
                        class="calculated"
                        hide-details="auto"
                        label="P&I Club"
                    />
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
            <v-btn class="align-self-end" @click="saveChanges(created)" color="primary" :disabled="!metadata" :loading="!loaded">Save</v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
import { getISO8601ShortDateFromDate } from './Tools'

  export default {
    name: 'MetaDataForm',
    props: ['metadata', 'save'],
    components: { },
    data: () => ({
        menu: false,
        isOkay: false,
        loaded: false,
        created: {
            BuiltYear: null,
            CallSign: null,
            Code: null,
            DW: null,
            Flag: null,
            GT: null,
            HMDeductibleAmount: null,
            HMclub: null,
            IMO: null,
            InsuredFI: null,
            InsuredHM: null,
            InsuredHI: null,
            Manager: null,
            Name: null,
            Net: null,
            Operator: null,
            Owner: null,
            PIDeductibleCargo: null,
            PIDeductibleCrew: null,
            PIDeductibleOther: null,
            PIclub: null,
            Type: null,
            finance: null,
            id: null,
        }
    }),
    watch: {
        metadata: function(newVal) {
            this.created = {... newVal}
        }
    },
    computed: {
        getPIClubs() {
            return this.$store.state.metadata.map(m => m.PIclub).filter(f => f)
        },
        getHMClubs() {
        return this.$store.state.metadata.map(m => m.HMclub).filter(f => f)
        },
    },
    methods: {
        getISO8601ShortDateFromDate,
        saveChanges(){
            this.loaded=false
            this.save(this.created).then(() => this.loaded=true)
        },
    },
    mounted: function(){
        this.created = {... this.metadata}
        this.loaded = true
    }
}
</script>

<style scoped>
.calculated.v-text-field--outlined >>> fieldset {
  border-color: rgba(192, 0, 250, 0.986);
}
</style>