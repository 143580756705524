import Vue from 'vue'
import VueRouter from 'vue-router'
import ClaimsView from '../views/ClaimsView.vue'
import Admin from '../views/Admin.vue'
const urls = require('../../config/urls')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/claims'
  },
  {
    path: '/claims/:id?',
    name: 'Claims',
    component: ClaimsView,
    beforeEnter: async (to, from, next) => {
      const headers = {
        headers: {
          'Cache-Control': ' no-cache',
          // 'Authorization': `Bearer ${token}`,  // send the access token through the 'Authorization' header
          'withCredentials': true,
        }
      }
      Vue.axios.get(urls.CREATESESSION, headers)
        .then(() =>
          Vue.axios.get(urls.CONFIRMSESSION, headers)
            .then(async (data) => {
              if(!to.query.user && data.data.user != null) {
                next({ path: to.path, query: data.data })
              } else {
                next()
              }
            }).catch(() => next({ name: 'Error', query: { error: 'Failed to establsh session' } }))
      )
        .catch((e) => next({ name: 'Error', query: { error: e } }))
    }
  },
  {
    path: '/logout',
    name: 'Logout',
    beforeEnter: async () => {
      window.location.href = urls.LOGOUT
    }

  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
    beforeEnter: async (to, from, next) => {
      const headers = {
        headers: {
          'Cache-Control': ' no-cache',
          // 'Authorization': `Bearer ${token}`,  // send the access token through the 'Authorization' header
          'withCredentials': true,
        }
      }
      Vue.axios.get(urls.CREATESESSION, headers)
        .then(() =>
          Vue.axios.get(urls.CONFIRMSESSION, headers)
            .then((data) => {
              if(!to.query['user']) {
                next({name: to.name, query: data.data})
              } else {
                next()
              }
            }).catch(() => next({ name: 'Error', query: { error: 'Failed to establsh session' } }))
      )
        .catch((e) => next({ name: 'Error', query: { error: e } }))
    }
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/error',
    name: 'Error',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Error.vue'),
  },
  {
    path: '/help',
    name: 'Help',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Help.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// router.beforeEach(authGuard)
export default router
