<template>
<v-container fluid class="fill-height">
   <v-row>
    <v-col cols="6">
      <v-card elevation="2" class="ma-1 grow" style="background-color: silver;">
        <v-card-title>Default amounts</v-card-title>
        <v-card-text class="fill-height">
          <v-row class="justify-center space-around ma-auto">

            <v-col cols="12">
              <v-currency-field
                  v-model="claim.HMDeductibleAmount"
                  label="H&M Deductible"
                  prefix="$"
                  @change="save()"
                  dense
              />
            </v-col>
            <v-col cols="12">
              <v-currency-field
                  v-model="claim.PIDeductibleCrew"
                  label="P&I Deductible Crew"
                  prefix="$"
                  @change="save()"
                  dense
              />
            </v-col>
            <v-col cols="12">
              <v-currency-field
                  v-model="claim.PIDeductibleCargo"
                  label="P&I Deductible Cargo"
                  prefix="$"
                  @change="save()"
                  dense
              />
            </v-col>
            <v-col cols="12">
              <v-currency-field
                  v-model="claim.PIDeductibleOther"
                  label="P&I Deductible Other"
                  prefix="$"
                  @change="save()"
                  dense
              />
            </v-col>

            <v-col cols="12">
              <v-spacer></v-spacer>
            </v-col>

            <v-col cols="12">
              <v-currency-field
                  v-model="claim.HMVesselInsuredAmount"
                  label="H&M Insured"
                  prefix="$"
                  @change="save()"
                  dense
              />
            </v-col>

            <v-col cols="12">
              <v-currency-field
                  v-model="claim.HMVesselInsuredAmountHI"
                  label="H&M Insured HI"
                  prefix="$"
                  @change="save()"
                  dense
              />
            </v-col>

            <v-col cols="12">
              <v-currency-field
                  v-model="claim.HMVesselInsuredAmountFI"
                  label="H&M Insured FI"
                  prefix="$"
                  @change="save()"
                  dense
              />
            </v-col>

          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="importDefaultsFromMeta()">Import all from defaults</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>

    <v-col cols="6">
   <v-card elevation="2" class="ma-1 grow">
      <v-card-title>Case related amounts</v-card-title>
        <v-card-text class="fill-height">
          <div v-if="isPerson">
            <v-row >
                <v-col cols="12">
                        <v-currency-field
                            v-model="claim.ReservedCost"
                            label="Reserved Amount"
                            prefix="$"
                            dense
                            @change="save()"
                        />
                </v-col>
                <v-col cols="12">
                        <v-combobox
                            :items="accounts"
                            v-model="claim.ReservedAccount"
                            dense
                            @change="save()"
                            label="Reserved Account" />
                </v-col>
            </v-row>
            <v-row >
              <v-col cols="12">
                <v-spacer></v-spacer>
              </v-col>
            </v-row>
            <v-row >
              <v-col cols="12" class="ma-auto">
                  <v-currency-field
                      v-model="claim.TotalIncidentCost"
                      label="Total Cost"
                      prefix="$"
                      dense
                      @change="save()"
                  />
              </v-col>
              <v-col cols="12" class="ma-auto">
                  <v-currency-field
                      v-model="claim.DeductibleAmount"
                      label="Deductible"
                      prefix="$"
                      dense
                      @change="save()"
                  />
              </v-col>
            </v-row>
            <v-row >
              <v-col cols="12" class="ma-auto">
                  <v-currency-field
                      v-model="calculateInsurersExpenses"
                      label="Insurers Expenses"
                      prefix="$"
                      readonly
                      persistent-hint
                      hint="Calculated field"
                      dense
                      @change="save()"
                  />
              </v-col>
              <v-col cols="12" class="ma-auto">
                <v-currency-field
                    v-model="claim.OwnersCost"
                    label="Owners Cost"
                    prefix="$"
                    readonly
                    persistent-hint
                    dense
                    @change="save()"
                    append-icon="mdi-calculator"
                    @click:append="claim.OwnersCost = calculateOwnersExpenses"
                />
              </v-col>
            </v-row>
          </div>
          <div v-if="!isPerson">
            <v-row>
              <v-col cols="12">
                      <v-currency-field
                          v-model="claim.ReservedCost"
                          label="Reserved Amount"
                          prefix="$"
                          dense
                          @change="save()"
                      />
              </v-col>
              <v-col cols="12">
                      <v-combobox
                          :items="accounts"
                          v-model="claim.ReservedAccount"
                          dense
                          @change="save()"
                          label="Reserved Account" />
              </v-col>
            </v-row>
            <v-row >
              <v-col cols="12">
                <v-spacer></v-spacer>
              </v-col>
            </v-row>
            <v-row >
                <v-col cols="12" class="ma-auto">
                    <v-currency-field
                        v-model="claim.TotalIncidentCost"
                        label="Total Cost"
                        prefix="$"
                        dense
                        @change="save()"
                    />
                </v-col>
                <v-col cols="12" class="ma-auto">
                    <v-currency-field
                        v-model="claim.DeductibleAmount"
                        label="Deductible"
                        prefix="$"
                        dense
                        @change="save()"
                    />
                </v-col>
                <v-col cols="12" class="ma-auto">
                    <v-currency-field
                        v-model="calculateInsurersExpenses"
                        label="Insurers Expenses"
                        persistent-hint
                        hint="Calculated field"
                        readonly
                        prefix="$"
                        dense
                        @change="save()"
                    />
                </v-col>
                <v-col cols="12" class="ma-auto">
                    <v-currency-field
                        v-model="claim.OwnersCost"
                        label="Owners cost"
                        prefix="$"
                        dense
                        append-icon="mdi-calculator"
                        @change="save()"
                        @click:append="claim.OwnersCost = calculateOwnersExpenses"
                    />
                </v-col>
                <v-col cols="12" class="ma-auto">
                    <v-currency-field
                        v-model="claim.SettledCost"
                        label="Settled Amount"
                        prefix="$"
                        @change="save()"
                        dense
                    />
                </v-col>
            </v-row>
          </div>
        </v-card-text>
      </v-card>

    </v-col>


   </v-row>


</v-container>
</template>


<script>

export default {
  name: 'AmountsTab',
  props: ['claimData', 'crewData', 'save'],
  components: {},

  computed: {
    calculateInsuredTotal() {
      return (this.claim.HMVesselInsuredAmount || 0)*1 +
        (this.claim.HMVesselInsuredAmountHI || 0)*1  +
        (this.claim.HMVesselInsuredAmountFI || 0)*1
    },
    calculateTotalAmount() {
      return (this.claim.OwnersOtherExpenses || 0)*1  +
            (this.claim.DeductibleAmount || 0)*1 +
            (this.claim.InsurersAmount || 0)*1
    },
    calculateOwnerExpenses() {
      return (this.claim.OwnersOtherExpenses || 0)*1  +
            (this.claim.DeductibleAmount || 0)*1
    },
    calculateInsurersExpenses() {
        return (this.claim.TotalIncidentCost > this.claim.DeductibleAmount) ? this.claim.TotalIncidentCost - this.claim.DeductibleAmount : 0
    },
    calculateOwnersExpenses() {
        return (this.claim.TotalIncidentCost > this.claim.DeductibleAmount) ?  this.claim.DeductibleAmount : this.claim.TotalIncidentCost
    },
    isPerson() {
      return this.claim.InsuranceType === 'PI' &&
      (this.claim.DamageType === 'ECR' || this.claim.DamageType === 'Crew')
    },
  },
  data: () => ({
      accounts: ['900 Insurance deductible','920 Damage cases below deductible','930 Crew cases below deductible','940 Extended Crew Cover'],
      loaded: false,
      claim: {
          CaseReference: null,
          ClaimClosed: null,
          ClaimCreated: null,
          ClaimsHandler: null,
          CrewAgency: null,
          DamageType: null,
          DamageTypeSubCategory: null,
          DeductibleAmount: null,
          Description: null,
          ExternalReference: null,
          FolderReference: null,
          HMClub: null,
          HMDeductibleAmount: null,
          HMVesselInsuredAmount: null,
          HMVesselInsuredAmountFI: null,
          HMVesselInsuredAmountHI: null,
          IncidentDate: null,
          InsuranceClaim: null,
          InsuranceType: null,
          Nationality: null,
          OutstandingCost: null,
          OwnersCost: null,
          PIClub: null,
          PIDeductibleCargo: null,
          PIDeductibleCrew: null,
          PIDeductibleOther: null,
          PolicyYear: new Date().getFullYear(),
          ReservedAccount: null,
          ReservedCost: null,
          SettledCost: null,
          Status: 'Open',
          Subject: null,
          TotalIncidentCost: null,
          Vessel: null,
        },
  }),
  methods: {
    saveChanges() {
      if(this.loaded)
        this.save()
    },
    importDefaultsFromMeta() {
      const meta = this.$store.state.metadata.find(f => f.Name === this.claim.Vessel)
      this.claim.HMDeductibleAmount = meta.HMDeductibleAmount
      this.claim.HMVesselInsuredAmount = meta.InsuredHM
      this.claim.HMVesselInsuredAmountFI = meta.InsuredFI
      this.claim.HMVesselInsuredAmountHI = meta.InsuredHI
      this.claim.PIDeductibleCargo = meta.PIDeductibleCargo
      this.claim.PIDeductibleCrew = meta.PIDeductibleCrew
      this.claim.PIDeductibleOther = meta.PIDeductibleOther
      this.saveChanges()
    }
  },
  watch: {
    claimData: {
      immediate: true,
      handler(newValue) {
        this.claim = newValue
      }
    },
    crewData: {
      immediate: true,
      handler(newValue) {
        this.crew = newValue
      }
    }
  },


  mounted: function(){
      this.claim = this.claimData
      this.$nextTick(function () {
        this.loaded=true
      })
  }
}
</script>

<style scoped>
.calculated.v-text-field--outlined >>> fieldset {
  border-color: rgba(192, 0, 250, 0.986);
}
</style>