<template>
<v-dialog
    transition="fade"
    v-model="showDialog"
    @click:outside="close"
>
<v-stepper v-model="e1">
    <v-stepper-header style="background-color: #8fb3c27a;">
        <v-stepper-step :complete="e1 > 1" step="1" >Case Basics</v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="e1 > 2" step="2">Case Specifics</v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="e1 > 3" step="3" >Amounts</v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="4" :complete="isOkay" >Confirmation</v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
            <v-stepper-content step="1">

                <v-row class="mt-1 justify-center">
                    <v-col cols="3" class="d-flex flex-row space-between">
                        <v-sheet class="pr-3">
                            <date-importer :parse="parseDate" />
                        </v-sheet>
                        <v-menu
                            v-model="menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="claim.IncidentDate"
                                :rules="[rules.required]"
                                label="Incident date"
                                append-icon="mdi-calendar"
                                outlined
                                dense
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                @click:append="menu = !menu"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="claim.IncidentDate"
                            @input="dateFromPicker" />
                        </v-menu>
                    </v-col>
                    <v-col cols="2">
                        <v-autocomplete
                            v-model="claim.Vessel"
                            :rules="[rules.required]"
                            @change="populateMetadata()"
                            outlined dense hide-details="auto"
                            item-text="Name"
                            item-value="Name"
                            :items="vessels"
                            label="Vessel">
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="2">
                        <v-text-field
                            :rules="[rules.required, cleanRef]"
                            outlined
                            dense
                            v-model="claim.CaseReference"
                            label="Case reference"></v-text-field>
                    </v-col>
                </v-row>
                <v-row class="justify-center">
                <v-col cols="3">
                    <v-text-field outlined dense hide-details="auto"  v-model="claim.ExternalReference" label="External reference"></v-text-field>
                </v-col>
                <v-col cols="3">
                    <v-text-field outlined dense hide-details="auto"  v-model="claim.FolderReference" label="Folder reference"></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" class="justify-center">
                    <v-btn-toggle
                        v-model="claim.InsuranceType"
                    >
                        <v-btn :disabled="claim.Vessel === null" color="grey" :value="item" v-for="(item, index) in getTypes" :key="index">
                        {{item}}
                        </v-btn>
                    </v-btn-toggle>
                </v-col>
            </v-row>
            <v-row>
                    <v-col cols="2" class="ma-auto">
                        <v-select
                            v-model="claim.DamageType"
                            v-if="getDamages!=null"
                            outlined
                            :items="getDamages"
                            label="Damage"
                            hide-details="auto"
                            dense
                            />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="2" class="ma-auto">
                        <v-select
                            v-model="claim.DamageTypeSubCategory"
                            v-if="getSubs!=null && getSubs.length>0"
                            outlined
                            :items="getSubs"
                            label="Sub category"
                            hide-details="auto"
                            dense
                            />
                    </v-col>
                </v-row>

                    <v-row justify="center">
                    <v-col cols="1">
                        <v-btn outlined color="secondary" @click="close()">Cancel</v-btn>
                    </v-col>
                    <v-col cols="1">
                        <v-btn color="primary" @click="e1 = 2" :disabled="step1LacksData">Next</v-btn>
                    </v-col>
                </v-row>
            </v-stepper-content>


            <v-stepper-content step="2">
                <div v-if="isAboutAPerson">
                <v-row class="mt-5">

                    <v-col cols="2" v-if="getInjuryIllnessTypes">
                        <v-combobox
                            outlined
                            :items="getInjuryIllnessTypes"
                            v-model="crew.InjuryIllnessType"
                            dense
                            hide-details="auto"
                            :label="claim.DamageTypeSubCategory+' Type'" />
                    </v-col>
                    <v-col cols="2" v-if="claim.DamageTypeSubCategory != 'Other'">
                        <v-combobox
                            outlined
                            :items="getBodyArea"
                            v-model="crew.BodyArea"
                            dense
                            hide-details="auto"
                            label="Body Area" />
                    </v-col>
                    <v-col cols="2" v-if="claim.DamageTypeSubCategory != 'Other'">
                        <v-combobox
                            outlined
                            :items="getMedicalRootCause"
                            v-model="crew.MedicalRootCause"
                            dense
                            hide-details="auto"
                            label="Medical Root Cause" />
                    </v-col>
                    <v-col cols="2">
                        <v-combobox
                            outlined
                            :items="getCrewAgencies"
                            v-model="claim.CrewAgency"
                            dense
                            hide-details="auto"
                            label="Crew Agency" />
                    </v-col>
                    <v-col cols="2">
                            <v-combobox
                                outlined
                                :items="getNationality"
                                v-model="claim.Nationality"
                                dense
                                hide-details="auto"
                                label="Nationality" />
                    </v-col>
                        <v-col cols="2"  class="d-flex justify-center">
                            <v-checkbox
                                outlined
                                v-model="crew.WorkRelated"
                                dense
                                :false-value="0"
                                :true-value="1"
                                hide-details="auto"
                                label="Work Related?" />
                        </v-col>

                </v-row>
                    <v-row>
                        <v-col cols="2">
                            <v-combobox
                                outlined
                                :items="getRanks"
                                v-model="crew.Rank"
                                dense
                                hide-details="auto"
                                label="Rank" />
                        </v-col>
                        <v-col cols="2">
                            <v-text-field dense hide-details="auto"  v-model="crew.Name" outlined label="Name"></v-text-field>
                        </v-col>
                        <v-col cols="2">
                                <v-slider
                                v-model="crew.Age"
                                color="orange"
                                label="Age"
                                min="18"
                                max="100"
                                thumb-label
                                :hint="`${crew.Age}`"
                                persistent-hint
                                ></v-slider>
                            <!-- <v-text-field dense hide-details="auto" v-model="crew.Age" outlined label="Age"></v-text-field> -->
                        </v-col>


                        <v-col cols="2">
                        <v-combobox
                            outlined
                            :items="getSignedOff"
                            v-model="crew.SignedOff"
                            dense
                            hide-details="auto"
                            label="Signed Off" />
                        </v-col>
                        <v-col cols="2">
                        <v-combobox
                            v-if="claim.DamageType==='ECR'"
                            outlined
                            :items="getECROfficerFamily"
                            v-model="crew.ECRCoverage"
                            dense
                            hide-details="auto"
                            label="ECR Coverage" />
                        </v-col>

                    </v-row>
                </div>
                <div>
                    <v-row class="mt-3">
                        <v-col cols="6">
                            <v-text-field outlined dense hide-details="auto"  v-model="claim.Subject" label="Subject"></v-text-field>
                        </v-col>
                        <v-col cols="2">
                            <v-text-field outlined dense hide-details="auto"  v-model="claim.PolicyYear" type="number" label="PolicyYear"></v-text-field>
                        </v-col>

                    </v-row>
                </div>
                <v-row>
                    <v-col cols="12">
                    <v-textarea  v-if="!isAboutAPerson" outlined dense hide-details="auto"  v-model="claim.Description" label="Description"></v-textarea>
                    <v-textarea  v-if="isAboutAPerson" outlined dense hide-details="auto"  v-model="crew.Description" label="Description"></v-textarea>
                    </v-col>
                </v-row>

                <v-row justify="center">
                    <v-col cols="1">
                        <v-btn @click="e1 = 1" color="warning">Previous</v-btn>
                    </v-col>
                    <v-col cols="1">
                        <v-btn outlined color="secondary" @click="close()">Cancel</v-btn>
                    </v-col>
                    <v-col cols="1">
                        <v-btn @click="e1 = 3" color="primary" :disabled="step2LacksData">Next</v-btn>
                    </v-col>
                </v-row>

            </v-stepper-content>


            <v-stepper-content step="3">
                <div v-if="isAboutAPerson">
                    <v-row class="justify-center space-around ma-auto">
                        <v-col cols="2">

                                <v-currency-field
                                    v-model="claim.ReservedCost"
                                    label="Reserved Amount"
                                    prefix="$"
                                    dense
                                />
                        </v-col>
                        <v-col cols="2">
                                <v-combobox
                                    :items="accounts"
                                    v-model="claim.ReservedAccount"
                                    dense
                                    label="Reserved Account" />
                        </v-col>
                    </v-row>
                    <v-row >
                        <v-col cols="12" class="justify-center">
                            <v-col cols="2" class="ma-auto">
                                <v-currency-field
                                    v-model="claim.TotalIncidentCost"
                                    label="Total Cost"
                                    prefix="$"
                                    dense
                                />
                            </v-col>
                            <v-col cols="2" class="ma-auto">
                                <v-currency-field
                                    v-model="claim.DeductibleAmount"
                                    label="Deductible"
                                    prefix="$"
                                    dense
                                />
                            </v-col>
                        </v-col>
                    </v-row>
                    <v-row >
                        <v-col cols="12" class="justify-center">
                            <v-col cols="2" class="ma-auto">
                                <v-currency-field
                                    v-model="calculateInsurersExpenses"
                                    label="Insurers Expenses"
                                    prefix="$"
                                    readonly
                                    persistent-hint
                                    hint="Calculated field"
                                    dense
                                />
                            </v-col>
                        </v-col>
                    </v-row>
                    <v-row >
                        <v-col cols="12" class="justify-center">
                        <v-col cols="2" class="ma-auto">
                                <v-currency-field
                                    v-model="calculateOwnersExpenses"
                                    label="Our Cost"
                                    prefix="$"
                                    readonly
                                    persistent-hint
                                    hint="Calculated field"
                                    dense
                                />
                            </v-col>
                        </v-col>
                    </v-row>

                </div>
                <div v-if="!isAboutAPerson">
                    <v-row class="justify-center space-around ma-auto">
                        <v-col cols="2">

                                <v-currency-field
                                    v-model="claim.ReservedCost"
                                    label="Reserved Amount"
                                    prefix="$"
                                    dense
                                />
                        </v-col>
                        <v-col cols="2">
                                <v-combobox
                                    :items="accounts"
                                    v-model="claim.ReservedAccount"
                                    dense
                                    label="Reserved Account" />
                        </v-col>
                    </v-row>
                    <v-row >
                        <v-col cols="12" class="justify-center">
                            <v-col cols="2" class="ma-auto">
                                <v-currency-field
                                    v-model="claim.TotalIncidentCost"
                                    label="Total Cost"
                                    prefix="$"
                                    dense
                                />
                            </v-col>
                            <v-col cols="2" class="ma-auto">
                                <v-currency-field
                                    v-model="claim.DeductibleAmount"
                                    label="Deductible"
                                    prefix="$"
                                    dense
                                />
                            </v-col>
                            <v-col cols="2" class="ma-auto">
                                <v-currency-field
                                    v-model="calculateInsurersExpenses"
                                    label="Insurers Expenses"
                                    prefix="$"
                                    dense
                                />
                            </v-col>
                            <v-col cols="2" class="ma-auto">
                                <v-currency-field
                                    v-model="claim.OwnersCost"
                                    label="Owners cost"
                                    prefix="$"
                                    dense
                                />
                            </v-col>
                            <v-col cols="2" class="ma-auto">
                                <v-currency-field
                                    v-model="claim.SettledAmount"
                                    label="Settled Amount"
                                    prefix="$"
                                    dense
                                />
                            </v-col>
                        </v-col>
                    </v-row>
                </div>
                <v-row justify="center">
                    <v-col cols="1">
                        <v-btn @click="e1 = 2" color="warning">Previous</v-btn>
                    </v-col>
                    <v-col cols="1">
                        <v-btn outlined color="secondary" @click="close()">Cancel</v-btn>
                    </v-col>
                    <v-col cols="1">
                        <v-btn @click="e1 = 4" color="primary">Next</v-btn>
                    </v-col>
                </v-row>
            </v-stepper-content>

            <v-stepper-content step="4">
                    <v-alert outlined v-if="!checkRequired" type="error">
                        Cannot create Claim!
                        Your data lacks one of the following attributes:
                        Vessel, date
                    </v-alert>

                    <v-alert v-if="checkRequired" type="success" outlined>
                        You are about to create a new Claim, please review that the data is correct. Use "Previous" button to go back and fix errors.
                    </v-alert>
                <v-row class="d-flex justify-center">
                    <v-col cols="4">
                        <v-treeview
                            activatable
                            dense
                            open-on-click
                            :items="getImportsTree"
                        >
                        <template v-slot:label="{ item, leaf }">
                            <v-row v-if="leaf">
                                <v-col cols="6" class="d-flex justify-left"><i>{{tryToMapName(item.name)}}</i>:</v-col>
                                <v-col v-if="!isNumber(item.name)" cols="6" class="d-flex justify-left">{{tryToMapValue(item.name, item.value)}}</v-col>
                                <v-col v-if="isNumber(item.name)" cols="6" class="d-flex justify-left"><v-currency-field hide-details v-model="claim[item.name]" prefix="$" dense readonly /></v-col>
                            </v-row>
                            <v-row v-if="!leaf">
                                <v-col cols="6" class="d-flex justify-left"><b>{{item.name}}</b></v-col>
                            </v-row>
                        </template>

                        </v-treeview>
                    </v-col>
                    <v-col cols="4">
                        <v-treeview
                            v-model="tree1"
                            activatable
                            dense
                            open-on-click
                            :items="getClaimTree"
                        >
                        <template v-slot:label="{ item, leaf }">
                            <v-row v-if="leaf">
                                <v-col cols="6" class="d-flex justify-left"><i>{{tryToMapName(item.name)}}</i>:</v-col>
                                <v-col v-if="!isNumber(item.name)" cols="6" class="d-flex justify-left">{{tryToMapValue(item.name, item.value)}}</v-col>
                                <v-col v-if="isNumber(item.name)" cols="6" class="d-flex justify-left"><v-currency-field hide-details v-model="claim[item.name]" prefix="$" dense readonly /></v-col>
                            </v-row>
                            <v-row v-if="!leaf">
                                <v-col cols="6" class="d-flex justify-left"><b>{{item.name}}</b></v-col>
                            </v-row>
                        </template>

                        </v-treeview>
                    </v-col>

                    <v-col cols="4">
                        <v-treeview
                            v-model="tree2"
                            activatable
                            dense
                            open-on-click
                            :items="getCrewTree"
                        >

                        <template v-slot:label="{ item, leaf }">
                            <v-row v-if="leaf">
                                <v-col cols="6" class="d-flex justify-left"><i>{{tryToMapName(item.name)}}</i>:</v-col>
                                <v-col v-if="!isNumber(item.name)" cols="6" class="d-flex justify-left">{{tryToMapValue(item.name, item.value)}}</v-col>
                                <v-col v-if="isNumber(item.name)" cols="6" class="d-flex justify-left"><v-currency-field v-model="claim[item.name]" label="Total Cost" prefix="$" dense /></v-col>
                            </v-row>
                            <v-row v-if="!leaf">
                                <v-col cols="6" class="d-flex justify-left"><b>{{item.name}}</b></v-col>
                            </v-row>
                        </template>
                        </v-treeview>
                    </v-col>

                </v-row>

                <v-row justify="center">
                    <v-col cols="1">
                        <v-btn @click="e1 = 3" color="warning">Previous</v-btn>
                    </v-col>
                    <v-col cols="1">
                        <v-btn outlined color="secondary" @click="close()">Cancel</v-btn>
                    </v-col>
                    <v-col cols="1">
                        <v-btn :disabled="!checkRequired" color="primary" @click="save(claim, crew)">Save and return</v-btn>
                    </v-col>
                </v-row>
            </v-stepper-content>
        </v-stepper-items>
    </v-stepper>
</v-dialog>
</template>
<script>
import { getISO8601ShortDateFromDate } from './Tools'
import DateImporter from './DateImporter.vue'
  export default {
    name: 'NewForm',
    props: ['dialog', 'save', 'close'],
    components: { DateImporter},
    data: () => ({
        rules: {
            required: value => !!value || 'Required.',
        },
        tree1: [],
        tree2: [],
        menu: false,
        isOkay: false,
        e1: 1,
        type: null,
        damage: null,
        sub: null,
        dirty: false,
        accounts: ['900 Insurance deductible','920 Damage cases below deductible','930 Crew cases below deductible','940 Extended Crew Cover'],
        crew: {
            Rank: null,
            Name: null,
            Age: null,
            InjuryIllnessType: null,
            BodyArea: null,
            Diagnosis: null,
            MedicalRootCause: null,
            WorkRelated: null,
            SignedOff: null,
            ECRCoverage: null,
            CompensationAmount: null,
            CompensationType: null,
            Description: null,
        },
        claim: {
            CaseReference: null,
            ClaimClosed: null,
            ClaimCreated: null,
            ClaimsHandler: null,
            CrewAgency: null,
            DamageType: null,
            DamageTypeSubCategory: null,
            DeductibleAmount: null,
            Description: null,
            ExternalReference: null,
            FolderReference: null,
            HMClub: null,
            HMDeductibleAmount: null,
            HMVesselInsuredAmount: null,
            HMVesselInsuredAmountFI: null,
            HMVesselInsuredAmountHI: null,
            IncidentDate: null,
            InsuranceClaim: null,
            InsuranceType: null,
            Nationality: null,
            OutstandingCost: null,
            OwnersCost: null,
            PIClub: null,
            PIDeductibleCargo: null,
            PIDeductibleCrew: null,
            PIDeductibleOther: null,
            PolicyYear: new Date().getFullYear(),
            ReservedAccount: null,
            ReservedCost: null,
            SettledCost: null,
            Status: 'Open',
            Subject: null,
            TotalIncidentCost: null,
            Vessel: null,
        },
        hierarchy: {
            'HM': {
                'Heavy Weather': null,
                'Fire/Explosion': null,
                'Collision, Contact, Grounding': ['Fenders', 'Floating object', 'Vessel', 'Propeller/rudder'],
                //'Property damage': ['Crane', 'Engine', 'Fire', 'Hull', 'Propeller/rudder', 'Other'],
                'Machinery': ['Main engine', 'Aux engine', 'Other'],
                'Vessel lost': null,
                'Other': null,
            },
            'PI': {
                'Cargo': null,
                'Collision': ['Fenders', 'Floating object', 'Vessel'],
                'Crew': [ 'Injury', 'Illness', 'Other'],
                'ECR': [ 'Injury', 'Illness', 'Other'],
                'Fine': null,
                'Pollution': null,
                'Property damage': ['Crane', 'Engine', 'Fire', 'Hull', 'Propeller/rudder', 'Other'],
                'Removal of wreck': null,
                'Third party damage':null,
                'Smuggling': null
            },
            'Strike': null,
            'Legal': null,
            'Charterers Liability': {
                'Cargo': null,
                'Crew': null,
                'Damage to Vessel': null,
                'Demurage': null,
                'Legal dispute': null,
                'Unsafe port': null
            }
        },
        personDamageHierarky: {
            'Crew': {   Injury: ['Amputation', 'Burn', 'Constitution', 'Crush', 'Cut', 'Fracture', 'General pain', 'Laceration', 'Other', 'Strain/Sprain'],
                        Illness: ['Infectious disease', 'Mental disease', 'Non-infectous disease', 'Physical disease'],
                        Other: ['Missing at sea', 'Stowaways', 'Desertion']
                    },
            'ECR': {   Injury: ['Amputation', 'Burn', 'Constitution', 'Crush', 'Cut', 'Fracture', 'General pain', 'Laceration', 'Other', 'Strain/Sprain'],
                        Illness: ['Infectious disease', 'Mental disease', 'Non-infectous disease', 'Physical disease'],
                        Other: ['Missing at sea', 'Stowaways', 'Desertion']
                    },
        },
        mappedDescription: {
            'CaseHandler': 'Handler',
            'CaseReference': 'Case reference',
            'DamageType': 'Damage',
            'DamageTypeSubCategory': 'Sub category',
            'DeductibleAmount': 'Deductible',
            'HMDeductibleAmount': 'H&M Deductible',
            'HMVesselInsuredAmount': 'H&M Insured value',
            'HMVesselInsuredAmountFI': 'H&M Insured FI',
            'HMVesselInsuredAmountHI': 'H&M Insured HI',
            'IncidentDate': 'Incident date',
            'InsuranceClaim': 'Claimed insurance? ',
            'InsuranceType': 'Type',
            'PIDeductibleCargo': 'Deductible Cargo',
            'PIDeductibleCrew': 'Deductible Crew',
            'PIDeductibleOther': 'Deductible Other',
            'TotalIncidentCost': 'Total cost',
            'BodyArea': 'Body Area',
            'InjryIllnessType': 'Injury / Illness type',
            'MedicalRootCause': 'Medical root cause',
            'WorkRelated': 'Work related? ',
            'PolicyYear': 'Policy year',
            'CrewAgency': 'Crew Agency',
            'OwnersCost': 'Owners cost'
        }
    }),
    computed: {
        getClaimTree() {
            let i=0
            const filteredClaim = {
                CaseReference: this.claim.CaseReference,
                ClaimClosed: this.claim.ClaimClosed,
                ClaimCreated: this.claim.ClaimCreated,
                ClaimsHandler: this.claim.ClaimsHandler,
                CrewAgency: this.claim.CrewAgency,
                DamageType: this.claim.DamageType,
                DamageTypeSubCategory: this.claim.DamageTypeSubCategory,
                DeductibleAmount: this.claim.DeductibleAmount,
                Description: this.claim.Description,
                ExternalReference: this.claim.ExternalReference,
                FolderReference: this.claim.FolderReference,
                IncidentDate: this.claim.IncidentDate,
                InsuranceClaim: this.claim.InsuranceClaim,
                InsuranceType: this.claim.InsuranceType,
                Nationality: this.claim.Nationality,
                OutstandingCost: this.claim.OutstandingCost,
                OwnersCost: this.claim.OwnersCost,
                PolicyYear: this.claim.PolicyYear,
                ReservedAccount: this.claim.ReservedAccount,
                ReservedCost: this.claim.ReservedCost,
                SettledCost: this.claim.SettledCost,
                Status: this.claim.Status,
                Subject: this.claim.Subject,
                TotalIncidentCost: this.claim.TotalIncidentCost,
                Vessel: this.claim.Vessel,
            }
            return [
                {
                    id: i++,
                    name: 'Claim',
                    children: this.getTreeStruct(filteredClaim, i)
                }
            ]
        },
        getCrewTree() {
            let i=0
            return [
                {
                    id: i++,
                    name: 'Crew',
                    children: this.getTreeStruct(this.crew, i)
                }
            ]
        },
        getImportsTree() {
            let i=0
            const importedTree = {
                HMClub: this.claim.HMClub,
                HMDeductibleAmount: this.claim.HMDeductibleAmount,
                HMVesselInsuredAmount: this.claim.HMVesselInsuredAmount,
                HMVesselInsuredAmountFI: this.claim.HMVesselInsuredAmountFI,
                HMVesselInsuredAmountHI: this.claim.HMVesselInsuredAmountHI,
                PIClub: this.claim.PIClub,
                PIDeductibleCargo: this.claim.PIDeductibleCargo,
                PIDeductibleCrew: this.claim.PIDeductibleCrew,
                PIDeductibleOther: this.claim.PIDeductibleOther,
            }
            return [
                {
                    id: i++,
                    name: 'Imported',
                    children: this.getTreeStruct(importedTree, i)
                }
            ]
        },

        calculateInsurersExpenses() {
            return (this.claim.TotalIncidentCost > this.claim.DeductibleAmount) ? (this.claim.TotalIncidentCost - this.claim.DeductibleAmount) : 0
        },
        calculateOwnersExpenses() {
            return (this.claim.TotalIncidentCost > this.claim.DeductibleAmount) ?  this.claim.DeductibleAmount : this.claim.TotalIncidentCost
        },
        step1LacksData() {
            if (this.claim.IncidentDate === null ||
                    this.claim.Vessel === null ||
                    this.claim.CaseReference === null ||
                    !this.isDrilledDown) return true
            return false
        },
        step2LacksData() {
            return false
        },
        isAboutAPerson() {
            return this.getDamages &&
                this.claim.InsuranceType === 'PI' &&
                (this.claim.DamageType === 'ECR' || this.claim.DamageType === 'Crew')

        },
        checkRequired() {
            return this.claim.VesselName != '' && this.claim.IncidentDate != null
        },
        vessels() {
            return this.$store.state.metadata
        },
        showDialog: {
            get () {
                return this.dialog
            },
            set (value) {
                this.$emit('input', value)
            }
        },

        getNationality() {
            return this.$store.state.claims
            .filter((f) => f.Nationality)
            .map((m) => m.Nationality.trim())
            .sort((a,b)=> a.localeCompare(b))
        },
        getECROfficerFamily() {
            return this.$store.state.crew
            .filter((f) => f.ECRCoverage)
            .map((m) => m.ECRCoverage.trim())
            .sort((a,b)=> a.localeCompare(b))
        },
        getBodyArea() {
        return this.$store.state.crew
            .filter((f) => f.BodyArea)
            .map((m) => m.BodyArea.trim())
            .sort((a,b)=> a.localeCompare(b))
        },
        getSignedOff() {
        return this.$store.state.crew
            .filter((f) => f.SignedOff)
            .map((m) => m.SignedOff.trim())
            .sort((a,b)=> a.localeCompare(b))
        },
        getInjuryIllness() {
        return this.$store.state.crew
            .filter((f) => f.InjuryIllness)
            .map((m) => m.InjuryIllness.trim())
            .sort((a,b)=> a.localeCompare(b))
        },
        getInjuryIllnessTypes() {
            return (this.claim.InsureanceType === 'PI' && (this.claim.DamageType ==='Crew' || this.claim.DamageType==='ECR')  && this.claim.DamageTypeSubCategory &&
                Object.prototype.hasOwnProperty.call(this.personDamageHierarky[this.claim.DamageType], this.claim.DamageTypeSubCategory)) ? this.personDamageHierarky[this.claim.DamageType][this.claim.DamageTypeSubCategory] : null
        },
        getMedicalRootCause() {
        return this.$store.state.crew
            .filter((f) => f.MedicalRootCause)
            .map((m) => m.MedicalRootCause.trim())
            .sort((a,b)=> a.localeCompare(b))
        },
        getDiagnosis() {
        return this.$store.state.crew
            .filter((f) => f.Diagnosis)
            .map((m) => m.Diagnosis.trim())
            .sort((a,b)=> a.localeCompare(b))
        },
        getWorkRelateds() {
        return this.$store.state.crew
            .filter((f) => f.WorkRelated)
            .map((m) => m.WorkRelated.trim())
            .sort((a,b)=> a.localeCompare(b))
        },
        getRanks() {
        return this.$store.state.crew
            .filter((f) => f.Rank)
            .map((m) => m.Rank.trim())
            .sort((a,b)=> a.localeCompare(b))
        },
        getCrewAgencies() {
        return this.$store.state.claims
            .filter((f) => f.CrewAgency)
            .map((m) => m.CrewAgency.trim())
            .sort((a,b)=> a.localeCompare(b))
        },

        getTypes() {
            let arr = Object.keys(this.hierarchy)
            return arr
        },
        getDamages() {
            if(!this.hierarchy[this.claim.InsuranceType]) return null
            let arr = Object.keys(this.hierarchy[this.claim.InsuranceType])
            return arr
        },
        getSubs() {
            if(!this.hierarchy || !this.hierarchy[this.claim.InsuranceType] || [this.claim.InsuranceType][this.claim.DamageType]) return null
            let arr = this.hierarchy[this.claim.InsuranceType][this.claim.DamageType]
            return arr
        },
        missingDamage() {
            return this.getDamages!=null && this.claim.DamageType===null
        },
        isDrilledDown() {
            if(!this.claim.InsuranceType) return false
            if(this.getDamages != null && !this.claim.DamageType) return false
            if(this.getSubs != null && !this.claim.DamageTypeSubCategory) return false
            return true
        }

    },
    watch: {
        'claim.InsuranceType': {
            handler(value) {
                this.claim.DamageType = null
                if(value==='HM'){
                    this.claim.DeductibleAmount = this.claim['HMDeductibleAmount']
                } else {
                    this.claim.DeductibleAmount = null
                }
            }
        },
        'claim.DamageType': {
            handler(value) {
                this.claim.DamageTypeSubCategory = null
                if(this.claim.InsuranceType === 'PI') {
                    if(value==='Crew' || value==='ECR') {
                        this.crew.Age = null
                        this.claim.DeductibleAmount = this.claim['PIDeductibleCrew']
                    } else if(value==='Cargo'){
                        this.claim.DeductibleAmount = this.claim['PIDeductibleCargo']
                        this.crew.Age = null
                    } else {
                        this.claim.DeductibleAmount = this.claim['PIDeductibleOther']
                        this.crew.Age = null
                    }
                }
            }
        },
        'claim.DamageTypeSubCategory': {
            handler(value) {
                this.crew.InjuryIllnessType = null
                if(this.claim.InsuranceType==='PI' && (this.claim.DamageType==='Crew' || this.claim.DamageType==='ECR')  && value==='Other') {
                    this.claim.DeductibleAmount = this.claim['PIDeductibleOther']
                }

            }
        },
        'crew.InjuryIllnessType': {
            handler() {
                this.crew.BodyArea = null
            }
        },
        'crew.BodyArea': {
            handler() {
                this.crew.MedicalRootCause = null
            }
        },
        'crew.MedicalRootCause': {
            handler() {
                this.crew.Diagnosis = null
            }
        },
    },
    methods: {
        getISO8601ShortDateFromDate,
        cleanRef(value){
            const hasCaseRef = this.$store.state.claims.filter(f=> f.CaseReference)
            return (value && hasCaseRef.find(f => f.CaseReference === value)) ? "That case reference has already been used for another claim" : true
        },
        isNumber(column){
            const numbers = ['HMVesselInsuredAmount', 'HMVesselInsuredAmountHI', 'HMVesselInsuredAmountFI',
                            'HMDeductibleAmount', 'PIDeductibleCrew', 'PIDeductibleCargo', 'PIDeductibleOther',
                            'DeductibleAmount', 'ReservedCost', 'SettledCost', 'OutstandingCost', 'OwnersCost', 'TotalIncidentCost']
            return numbers.includes(column)
        },
        tryToMapName(colName){
            let result = (this.mappedDescription[colName]) ? this.mappedDescription[colName] : colName
            return result
        },
        tryToMapValue(colName, val){
            let result = val
            if(colName==='WorkRelated') { result = (val===1) ? 'yes' : 'no'}
            if(colName==='InsuranceClaim') { result = (val===1) ? 'yes' : 'no'}
            if(colName==='InsuranceType') { result = (val==='PI') ? 'P&I' : ((val === 'HM') ? 'H&M' : val)}
            return result
        },
        getTreeStruct(tree, i){
            return Object.keys(tree).sort((a,b)=> a.localeCompare(b)).map(m => {
                        if(tree[m] != null) return {
                            'id': i++,
                            'name': m,
                            'value': tree[m]
                        }
                    }).filter(f => f)
        },
        parseDate(date) {
            this.claim.IncidentDate = getISO8601ShortDateFromDate(Date.parse(date))
        },
        dateFromPicker(ed) {
            this.claim.IncidentDate = ed
            this.menu = false
        },
        // Step 2
        populateMetadata: function () {
            const picked = this.$store.state.metadata.find(p => p.Name === this.claim.Vessel)
            this.claim.PIClub = picked['PIclub'] ?? null
            this.claim.HMClub = picked['HMclub'] ?? null
            // this.claim.DeductableAmount = picked['DeductableAmount']
            this.claim['HMVesselInsuredAmount'] = picked['InsuredHM'] ?? null
            this.claim['HMVesselInsuredAmountHI'] = picked['InsuredHI'] ?? null
            this.claim['HMVesselInsuredAmountFI'] = picked['InsuredFI'] ?? null
            this.claim['HMDeductibleAmount'] = picked['HMDeductibleAmount'] ?? null
            this.claim['PIDeductibleCrew'] = picked['PIDeductibleCrew'] ?? null
            this.claim['PIDeductibleCargo'] = picked['PIDeductibleCargo'] ?? null
            this.claim['PIDeductibleOther'] = picked['PIDeductibleOther'] ?? null
            const year = `${new Date().getFullYear()}`
            this.claim.CaseReference = `${picked.Code}-${year.substring(year.length-2,)}`
        },
    },
    mounted: function(){
        this.claim.CaseHandler = this.$store.state.handler
    }
}
</script>

<style lang="scss">
.v-btn-toggle > .v-btn.v-btn {
    .v-icon {
      color: darkslategrey !important;
    }
  .v-btn__content {
    color:  darkslategrey !important;
    font-weight: bold;
  }
}

.v-btn-toggle > .v-btn.v-btn--active:before {
    opacity: 0;
}
.v-btn-toggle > .v-btn.v-btn--active {
    background-color: #1976d2 !important;
  .v-icon {
    color: white !important;
  }
  .v-btn__content {
    color:  white !important;
    font-weight: bold;
  }

}

</style>