import Vuex from 'vuex'
import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
// Import getInstance from the auth wrapper
import { getInstance } from '@/auth';
// import dependency to handle HTTP request to our back end
import config from '../../config'
const urls = require('../../config/urls.js')
const bucketkey = 'gbnclaims'

Vue.use(Vuex)
Vue.use(VueAxios, axios)


axios.interceptors.response.use(function (response) {
    return response
}, function (error) {
    if (error.response && error.response.status === 401) {
        console.log('Setting callback to: ', window.location.href)
        window.location.href = `${urls.LOGIN}?callback=${window.location.href}`
    } else {
        if (this && this.$store) {
            this.$store.dispatch('showError', 'Error while booting application, could not load all data. \n', error)
            return Promise.reject(error)
        } else {
                const message = (error.message === "Network Error") ? `${error.message}. Do you have a working internet connection? / Is API down?` : error.message
                return Promise.reject(message)
            }
        }
})

axios.defaults.withCredentials = true;

//to handle state
const state = {
    loading: true,
    handler: null,
    error: '',
    token: null,
    headers: {},
    vessels: [],
    claims: [],
    crew: [],
    openClaim: null,
    openClaimTime: null,
    resetClaim: {},
    dimensions: [],
    metadata: [],
    audits: [],
    attachments: [],
    download: null,
}


//to handle state
const getters = {}

//to handle actions
const actions = {
    showLoader({ commit }) {
        return commit('SET_LOADING', true)
    },
    showError({ commit }, msg) {
        return commit('SET_ERROR', msg)
    },
    hideError({ commit }) {
        return commit('SET_ERROR', '')
    },
    hideLoader({ commit }) {
        return commit('SET_LOADING', false)
    },

    initHeaders({ commit }) {
        return commit('SET_HEADERS')
    },

 /*    async createAndValidateSession({ commit }) {
        console.log("Initiating session")
        await Vue.axios.get(config.getUrls()['CREATESESSION'], state.headers)
        console.log("Confirming session")
        await Vue.axios.get(config.getUrls()['CONFIRMSESSION'], state.headers).catch((e) => commit('SET_ERROR', e))
        console.log("Successfully validated session")
        commit('NOTHING')
    },
 */

    getToken(context) {
        return new Promise((resolve, reject) => {
            const instance = getInstance();
            instance.$watch("loading", loading => {
                if (loading === false && instance.isAuthenticated) {
                    /*                     instance
                    .getTokenSilently()
                    .then(authToken => {
                        context.commit("SET_TOKEN", authToken);
                        resolve(authToken);
                    })
                    .catch(error => {
                        reject(error);
                    });
                    */                 console.log("getToken temp disabled", context, resolve, reject)
                }
            });
        });
    },
    setHandler({ commit }, { user }) {
        return commit('SET_HANDLER', user)
    },
    setToken({ commit }, { token }) {
        return commit('SET_TOKEN', token)
    },
    async openClaim({ commit }, { claim }) {        
        let readClaim = await Vue.axios.get(`${config.getUrls()['CLAIMS']}/${claim.id}`, state.headers)
        let readCrew = await Vue.axios.get(`${config.getUrls()['CREW']}`, state.headers)
        await commit('SET_CREW', readCrew.data)
        return commit('OPEN_CLAIM', readClaim.data[0])
    },
    unloadClaim({ commit }) {
        return commit('OPEN_CLAIM', null)
    },
    getAttachments({ commit }) {
        /*         let apiheaders ={
            headers: {
                'authorization': 'gearbulk_claims ' + gbnclaims
            }
        } */
        let apiheaders = state.headers
        return Vue.axios
            .get(`${config.getUrls()['CLAIMS']}/attachments`, apiheaders)
            .then((response) => {
                return commit('SET_ATTACHMENTS', response.data)
            })
    },
    async uploadFile({ commit }, file) {
        let apiheaders = state.headers
        let date = new Date()
        const year = [date.getFullYear(), new String(date.getMonth() + 1).padStart(2, '0'), new String(date.getDate()).padStart(2, '0')];
        const time = [new String(date.getHours()).padStart(2, '0'), new String(date.getMinutes()).padStart(2, '0')]
        file.name = `${year.join('-')} ${time.join('.')} ${file.name}`
        await Vue.axios.post(`${config.getUrls()['CLAIMS']}/attachments`, file, apiheaders)
        let response =
            await Vue.axios.get(`${config.getUrls()['CLAIMS']}/attachments`, apiheaders)
        commit('SET_ATTACHMENTS', response.data)
        response = await Vue.axios.get(`${config.getUrls()['AUDITS']}`, apiheaders)
        return commit('SET_AUDITS', response.data)
    },
    async downloadFile({ commit }, filename) {
        let apiheaders = state.headers
        console.log('API headers ', filename)
        apiheaders.headers.bucket = bucketkey
        const response = await Vue.axios
            .get(`${config.getUrls()['CLAIMS']}/attachments/${filename}`, apiheaders)
        commit('NOTHING')
        console.log('DownloadFile ready ', response.data)
        return response.data.data

    },
    resetClaim({ commit }) {
        return commit('RESET_CLAIM')
    },

    getVessels({ commit }) {
        return Vue.axios.get(`${config.getUrls()['VESSELS']}`, state.headers)
            .then(response => {
                return commit('SET_VESSELS', response.data)
            })

    },
    getDimensions({ commit }) {
        return Vue.axios.get(`${config.getUrls()['DIMENSIONS']}`, state.headers)
            .then(response => {
                return commit('SET_DIMENSIONS', response.data)
            })

    },
    getMetadata({ commit }) {
        return Vue.axios.get(`${config.getUrls()['METADATA']}`, state.headers)
            .then(response => {
                return commit('SET_METADATA', response.data)
            })

    },
    async saveMetadata({ commit }, { metadata }) {
        let user_headers = state.headers
        await Vue.axios.put(`${config.getUrls()['METADATA']}/${metadata.id}`, metadata, user_headers)
        let response = await Vue.axios.get(`${config.getUrls()['METADATA']}`, user_headers)
        return commit('SET_METADATA', response.data)
    },
    async createMetadata({ commit }, {metadata}) {
        let user_headers = state.headers
        user_headers.headers.user = state.handler
        commit('NOTHING')
        return await Vue.axios.post(`${config.getUrls()['METADATA']}`, metadata, user_headers)
    },

    async getClaims({ commit }) {
        await commit('RESET_CLAIMS')
        return Vue.axios.get(`${config.getUrls()['CLAIMS']}`, state.headers)
            .then(response => {
                return commit('SET_CLAIMS', response.data)
            })

    },
    createClaim({ commit }, { newItem, newCrew }) {
        let user_headers = state.headers
        user_headers.headers.user = state.handler
        return Vue.axios
            .post(`${config.getUrls()['CLAIMS']}`, newItem, user_headers)
            .then((data) => Vue.axios.post(`${config.getUrls()['CREW']}/${data.data[0].id}`, newCrew, data, user_headers)
                .then(() => Vue.axios.get(`${config.getUrls()['CLAIMS']}`)
                    .then(response => {
                        return commit('SET_CLAIMS', response.data)
                    })
                )
            )
    },
    async saveClaim({ commit }, { claim }) {
        let user_headers = state.headers
        await Vue.axios.put(`${config.getUrls()['CLAIMS']}/${claim.id}`, claim, user_headers)
        let response = await Vue.axios.get(`${config.getUrls()['CLAIMS']}`, user_headers)
        await commit('SET_CLAIMS', response.data)
        response = await Vue.axios.get(`${config.getUrls()['AUDITS']}`, user_headers)
        return commit('SET_AUDITS', response.data)
    },
    async getCrew({ commit }) {
        return Vue.axios.get(`${config.getUrls()['CREW']}`, state.headers)
            .then(response => {
                return commit('SET_CREW', response.data)
            })

    },
    saveCrew({ commit }, { crew }) {
        let user_headers = state.headers
        return Vue.axios
            .put(`${config.getUrls()['CREW']}/${crew.ClaimId}`, crew, user_headers)
            .then(() => Vue.axios.get(`${config.getUrls()['CREW']}`, user_headers)
                .then(response => {
                    return commit('SET_CREW', response.data)
                })
            )
    },
    async getAudits({ commit }) {
        await commit('RESET_AUDITS')
        return Vue.axios.get(`${config.getUrls()['AUDITS']}`, state.headers)
            .then(response => {
                return commit('SET_AUDITS', response.data)
            })

    },
    createLog({ commit }, { newItem }) {
        return Vue.axios
            .post(`${config.getUrls()['AUDITS']}`, newItem, state.headers)
            .then(() => Vue.axios.get(`${config.getUrls()['AUDITS']}`, state.headers)
                .then(response => {
                    return commit('SET_AUDITS', response.data)
                })
            )
    },
    updateLog({ commit }, { audit }) {
        return Vue.axios
            .put(`${config.getUrls()['AUDITS']}/${audit.id}`, audit, state.headers)
            .then(() => commit('RESET_AUDITS'))
            .then(() => Vue.axios.get(`${config.getUrls()['AUDITS']}`, state.headers)
                .then(response => {
                    return commit('SET_AUDITS', response.data)
                })
            )
    },
    removeLog({ commit }, { audit }) {
        return Vue.axios
            .delete(`${config.getUrls()['AUDITS']}/${audit.id}`, state.headers)
            .then(() => commit('RESET_AUDITS'))
            .then(() => Vue.axios.get(`${config.getUrls()['AUDITS']}`, state.headers)
                .then(response => {
                    return commit('SET_AUDITS', response.data)
                })
            )
    },
}

//to handle mutations
const mutations = {
    NOTHING() {

    },
    SET_LOADING(state, loading) {
        state.loading = loading
    },
    SET_ERROR(state, msg) {
        state.error = msg
    },
    SET_HEADERS(state) {
        state.headers = {
            headers: {
                'Cache-Control': ' no-cache',
                // 'Authorization': `Bearer ${token}`,  // send the access token through the 'Authorization' header
                'withCredentials': true,
            }
        }
    },
    SET_VESSELS(state, vessels) {
        state.vessels = vessels
    },
    SET_DIMENSIONS(state, dimensions) {
        state.dimensions = dimensions
    },
    SET_METADATA(state, metadata) {
        state.metadata = metadata
    },
    SET_AUDITS(state, audits) {
        state.audits = audits
    },
    RESET_AUDITS(state) {
        state.audits = null
    },
    SET_ATTACHMENTS(state, attachments) {
        state.attachments = attachments.data.list
    },
    SET_FILE(state, file) {
        state.download = file
    },
    RESET_CLAIMS(state) {
        state.claims = null
    },
    SET_HANDLER(state, user) {
        state.handler = user
    },
    SET_CLAIMS(state, claims) {
        state.claims = claims.map((c) =>
            c.IncidentDate
                ? { ...c, IncidentDate: c.IncidentDate.substring(0, 10) }
                : c
        )
    },
    SET_CREW(state, claims) {
        state.crew = claims.map((c) =>
            c.IncidentDate
                ? { ...c, IncidentDate: c.IncidentDate.substring(0, 10) }
                : c
        )
    },
    OPEN_CLAIM(state, claim) {
        state.openClaim = claim
        state.openClaimTime = Date.now()
        state.resetClaim = { ...claim }
    },

    RESET_CLAIM(state) {
        state.openClaim = state.claims.find(f => f.id === state.openClaim.id)
    }

}



//export store module
export default new Vuex.Store({
    state,
    getters,
    actions,
    mutations
})
/** we have just created a boiler plate for our vuex store module**/
