<template>
    <v-card
      elevation="2"
      class="ma-1"
      outlined
      height="100%"
    >

        <v-card-text>
          <v-row justify="end">
            <v-col cols="12" md="2">
              <v-checkbox
                v-model="showAll"
                label="Show all"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="12" md="8">
              <v-text-field class="mb-4"
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                clearable
              ></v-text-field>


            </v-col>
            <v-col cols="12" md="2">

              <v-btn
              class="ma-4"
              color="primary accent-2"
              @click="newFormDialog = true"
              outlined
            >
              <v-icon>mdi-chat-plus</v-icon>
            </v-btn>

            </v-col>
          </v-row>
          <chat :search="search" :showAll="showAll"></chat>
          <new-log-form v-if="newFormDialog" :dialog="newFormDialog" :save="saveNew" :close="closeNewDialog"/>
        </v-card-text>
    </v-card>
</template>

<script>
import { getISO8601dateFromDate } from './Tools'
import NewLogForm from './NewLogForm.vue'
import Chat from './Chat.vue'
  export default {
    name: 'LogList',
    props: ['claimid'],
    components: {
       NewLogForm, Chat
    },
    data: () => ({
      newFormDialog: false,
      menu: false,
      item: {},
      search: '',
      showAll: false,
    }),
    computed: {
      list() {
        let all = this.$store.state.audits && this.$store.state.audits.filter(a => a.CaseId === this.claimid).map(m => ({...m, downloading: false, showInfo: false}))
        return (this.showAll) ? all : all.filter((f) => f.Category === 'UserMessage')
      },
       headers() {
         // Minimized
        return [
          { text: 'User', value: 'CreatedBy', width: '150px'},
            { text: 'Category', value: 'Category', width: '110px', align: 'middle'},
            { text: 'Created', value: 'CreatedDate', width: '160px'},
            { text: 'Message', value: 'Message', width: '100%'},
          ]
      },
    },
    mounted: function(){
      if(!this.list) {
        return ""
      }

    },
    methods: {
      getISO8601dateFromDate,
      mapCategoryToTooltip(cat){
        const map = {
          'UserMessage': 'Click this icon to see a popup displaying the full message',
          'CreatedClaim': 'This was auto generated by the system to reflect that a claim was created',
          'ModifiedClaim': 'Click this icon to see a popup displaying the full message',
          'AddedAttachment': 'Click this icon to download attachment'
        }
        return map[cat]
      },
      showPopupMessage(item) {
        item.showInfo = true
      },
      clickAction(item) {
        if(item.Category === 'AddedAttachment') { this.tryDownload(item)}
        if(item.Category === 'UserMessage' || item.Category === 'ModifiedClaim') { this.showPopupMessage(item)}
      },
      async tryDownload(item) {

        item.downloading = true
        const data = await this.$store.dispatch('downloadFile', item.Message)
        const ext = data.filename.split('.')[1]
        const linkSource = `data:application/${ext};base64,${data.data}`
        const byteString = atob(linkSource.split(',')[1])
        const mimeString = linkSource
          .split(',')[0]
          .split(':')[1]
          .split(';')[0]

        // write the bytes of the string to an ArrayBuffer
        const ab = new ArrayBuffer(byteString.length)
        const ia = new Uint8Array(ab)
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i)
        }

        // write the ArrayBuffer to a blob, and you're done
        const fileBlob = new Blob([ab], { type: mimeString })

        /**
         * Internet Explorer stuff!
         */
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(fileBlob, `${data.filename}`)
          return
        }
        //Use atob only to decode Base64 to binary and show some information about the PDF file (note that I skipped all checks)
        const url = window.URL.createObjectURL(fileBlob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${data.filename}`)
        document.body.appendChild(link)
        link.click()

        link.remove()
        item.downloading = false
      },
      row_classes(item) {
          if (item.Category != 'UserMessage') {
            return "generated"; //can also return multiple classes e.g ["orange","disabled"]
          }
          return "user"
      },
      mapIconToCategory(cat) {
        const categoryMap = {
          'UserMessage': 'mdi-chat',
          'CreatedClaim': 'mdi-creation',
          'ModifiedClaim': 'mdi-square-edit-outline',
          'AddedAttachment': 'mdi-file-outline'
        }
        return categoryMap[cat]
      },
      saveNew(message) {
        const newItem = {
          CaseId: this.$store.state.openClaim.id,
          Message: message,
          Category: 'UserMessage',
        }
        this.$store.dispatch('createLog', {newItem: newItem})
        this.newFormDialog=false
      },
      closeNewDialog() {
        this.newFormDialog = false
      },
    }
  }
</script>

<style lang="css" >
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
.scroll {
  overflow-y: scroll;
}
tr.altbg:nth-child(even) td {
  background-color: cornsilk !important;
}
tr.user td {
  background-color: #c8d6df !important;
}

</style>